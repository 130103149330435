import config from "../config.json";
import {
  QuestionRow,
  QuizConfigRow,
  SubmissionRow,
  VirtualKeyboardConfig,
  VirtualKeyboardConfigRow,
} from "./types";
import { Question, QuizConfig, AnswerOption } from "../store/quiz/types";
import { v4 as uuidv4 } from "uuid";
import { SubmissionState, QuizAnswer } from "../store/submission/types";

// import like this, to avoid type declarations not existing for google-spreadsheet
const { GoogleSpreadsheet } = require("google-spreadsheet");

// Fetches the configured google sheet document
export async function fetchGoogleDoc(docId: string) {
  const { auth } = config.googleSheets;

  const doc = new GoogleSpreadsheet(docId);
  doc.useServiceAccountAuth(auth);
  await doc.loadInfo();

  return doc;
}

//TODO: handle save better
export async function autoPopulateIds(
  rows: QuestionRow[]
): Promise<QuestionRow[]> {
  return Promise.all(
    rows.map(async (row: QuestionRow) => {
      if (!row["ID (do not change!)"]) {
        row["ID (do not change!)"] = uuidv4();
        await row.save();
      }
      return row;
    })
  );
}

export function questionsFromRows(rows: QuestionRow[]): Question[] {
  // Sort out all undefined answers
  const answers = (row: QuestionRow): AnswerOption[] =>
    [row["Answer 1"], row["Answer 2"], row["Answer 3"], row["Answer 4"]]
      .filter((answer) => !!answer)
      .map((answer, idx) => ({ text: String(answer), id: idx }));

  return rows.map((row: QuestionRow) => {
    // Directly copy out values
    const question: Question = {
      id: row["ID (do not change!)"],
      text1: row["Heading (bold text)"],
      text2: row["Subheading"],
      correctAnswer: parseInt(row["Correct Answer ( 1 - 4 )"]) - 1,
      imageURL: row["Image"],
      //Todo: Fix the typing of this... This is... Not pretty
      answers: answers(row),
    };
    return question;
  });
}

export function quizConfigFromRows(rows: QuizConfigRow[]): QuizConfig {
  // Start by checking how many rows we got
  if (rows.length !== 1) {
    console.warn(
      "quizConfigFromRows: got more than one QuizConfigRow. The first row will be used.",
      rows
    );
  }

  const row: QuizConfigRow = rows[0];

  return {
    frontpageConfig: {
      heading: row["Frontpage heading (bold)"],
      subheading: row["Frontpage subheading"],
      image: row["Frontpage image"],
      isImageFullBleed: row["Frontpage image bleed"] === "TRUE",
    },
    generalConfig: {
      noQuestions: parseInt(row["Questions per quiz"]),
      backgroundImage: row["Background Image (1080 x 1920)"],
      backgroundImageLandscape: row["Background Image (1920 x 1080)"],
      frontpageBackgroundImage: row["Frontpage Background Image (1080 x 1920)"],
      frontpageBackgroundImageLandscape: row["Frontpage Background Image (1920 x 1080)"],
      submitpageBackgroundImage: row["Submitpage Background Image (1080 x 1920)"], 
      submitpageBackgroundImageLandscape: row["Submitpage Background Image (1920 x 1080)"]
    },
    submitScreenConfig: {
      subheading: row["Submitpage subheading"],
      inputPlaceholder: row["Submitpage input placeholder"],
      image: row["Submitpage image"],
      isImageFullBleed: row["Submitpage image bleed"] === "TRUE",
    },
    stylingConfig: {
      headingColor: String(row["Heading color"]),
      subheadingColor: String(row["Subheading color"]),
      headingCorrectColor: String(row["Heading correct color"]),
      headingWrongColor: String(row["Heading wrong color"]),
      answerTextColor: String(row["Answer text color"]),
      answerCheckboxColor: String(row["Answer checkbox color"]),
      answerColor: String(row["Answer color"]),
      questionIndicatorColor: String(row["Question indicator color"]),
      buttonTextColor: String(row["Button text color"]),
      buttonDisabledTextColor: String(row["Button disabled text color"]),
      buttonBorderColor: String(row["Button border color"]),
      buttonBackgroundColor: String(row["Button background color"]),
      buttonLoadingColor: String(row["Button loading color"]),
      inputTextColor: String(row["Input text color"]),
      inputDisabledTextColor: String(row["Input disabled text color"]),
      inputBorderColor: String(row["Input border color"]),
    },
  };
}

export function submissionRowsFromState(
  submissionState: SubmissionState
): SubmissionRow[] {
  const submissionId = uuidv4();
  // create a timestamp
  const today = new Date();
  const timestamp = `${today.getHours()}:${today.getMinutes()} ${today.getDate()}/${today.getMonth()}/${today.getFullYear()}`;
  return submissionState.quizAnswers.map((answer: QuizAnswer) => ({
    UserIdentifier: submissionState.userIdentifier,
    Question: answer.questionID,
    Answer: answer.answerID,
    SubmissionID: submissionId,
    IsCorrect: answer.correct,
    Timestamp: timestamp,
  }));
}

export function virtualKeyboardConfigFromRows(
  rows: VirtualKeyboardConfigRow[]
): VirtualKeyboardConfig {
  // Start by checking how many rows we got
  if (rows.length !== 1) {
    console.warn(
      "virtualKeyboardConfigFromRows: got more than one virtualKeyboardRow. The first row will be used.",
      rows
    );
  }

  const row: VirtualKeyboardConfigRow = rows[0];

  return {
    layout: {
      default: JSON.parse(`[${row["Default keyboard layout"]}]`),
      shift: JSON.parse(`[${row["Shift keyboard layout"]}]`),
      numbers: JSON.parse(`[${row["Numbers keyboard layout"]}]`),
    },
    allLayoutsCss: String(row["All layouts keyboard css"]),
    defaultLayoutCss: String(row["Default keyboard css"]),
    shiftLayoutCss: String(row["Shift keyboard css"]),
    numbersLayoutCss: String(row["Numeric keyboard css"]),
  };
}
