import { AppThunk } from "..";
import {
  fetchGoogleDoc,
  questionsFromRows,
  quizConfigFromRows,
  autoPopulateIds,
  virtualKeyboardConfigFromRows,
} from "../../googleSheets/functions";
import { Question, QuizConfig } from "./types";
import {
  QuestionRow,
  QuizConfigRow,
  VirtualKeyboardConfig,
  VirtualKeyboardConfigRow,
} from "../../googleSheets/types";
import { gotQuiz, startFetchQuiz } from "./actions";
import { preloadImages } from "../../utils";
import { setVirtualKeyboardConfig } from "../virtualKeyboard/actions";

export const fetchQuiz = (quizId: string): AppThunk => async (dispatch) => {
  // Signal we're fetching the quiz
  dispatch(startFetchQuiz());

  // Fetch the doc
  const doc = await fetchGoogleDoc(quizId);

  // Format retrieved document
  const rawQuestionRows: QuestionRow[] = await doc.sheetsByIndex[0].getRows();
  const questionRows = await autoPopulateIds(rawQuestionRows);
  const questions: Question[] = questionsFromRows(questionRows);

  const quizConfigSheetRows: QuizConfigRow[] = await doc.sheetsByIndex[1].getRows();
  const quizConfig: QuizConfig = quizConfigFromRows(quizConfigSheetRows);

  const virtualKeyboardConfigRows: VirtualKeyboardConfigRow[] = await doc.sheetsByIndex[3].getRows();
  const virtualKeyboardConfig: VirtualKeyboardConfig = virtualKeyboardConfigFromRows(
    virtualKeyboardConfigRows
  );

  // Preload all the images
  const imagesToPreload = [
    quizConfig.frontpageConfig.image,
    quizConfig.generalConfig.backgroundImage,
    quizConfig.generalConfig.backgroundImageLandscape, 
    quizConfig.generalConfig.frontpageBackgroundImage, 
    quizConfig.generalConfig.frontpageBackgroundImageLandscape, 
    quizConfig.generalConfig.submitpageBackgroundImage, 
    quizConfig.generalConfig.submitpageBackgroundImageLandscape, 
    quizConfig.submitScreenConfig.image,
    ...questions.map((question) => question.imageURL),
  ]
    // Filter out empty image strings
    .filter((image) => !!image)
    // Unwrap optionals
    .map((image) => String(image));

  await preloadImages(imagesToPreload);

  // Send formatted data to store
  dispatch(gotQuiz(questions, quizConfig));
  dispatch(setVirtualKeyboardConfig(virtualKeyboardConfig));
};
