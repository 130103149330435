import { VirtualKeyboardConfig } from "../../googleSheets/types";

export interface VirtualKeyboardState {
  isOpen: boolean;
  layoutName: LayoutName;
  config: VirtualKeyboardConfig;
}

// --- Actions
export const OPEN_KEYBOARD = "[Virtual Keyboard] Opening virtual keyboard";
export interface OpenKeyboardAction {
  type: typeof OPEN_KEYBOARD;
}

export const CLOSE_KEYBOARD = "[Virtual Keyboard] Closing virtual keyboard";
export interface CloseKeyboardAction {
  type: typeof CLOSE_KEYBOARD;
}

export enum LayoutName {
  DEFAULT = "default",
  SHIFT = "shift",
  ABC = "abc",
  NUMBERS = "numbers",
}

export const SET_LAYOUT_NAME = "[Virtual Keyboard] Setting layout name";
export interface SetLayoutNameAction {
  type: typeof SET_LAYOUT_NAME;
  layoutName: LayoutName;
}

export const SET_VIRTUAL_KEYBOARD_CONFIG =
  "[Virtual Keyboard] Setting the virtual keyboard config";
export interface SetVirtualKeyboardConfig {
  type: typeof SET_VIRTUAL_KEYBOARD_CONFIG;
  virtualKeyboardConfig: VirtualKeyboardConfig;
}

export type VirtualKeyboardActionTypes =
  | OpenKeyboardAction
  | CloseKeyboardAction
  | SetLayoutNameAction
  | SetVirtualKeyboardConfig;
