import React from "react";
import { FullPageLayout } from "../components/fullPageLayout";
import styled from "styled-components";
import { Question } from "../store/quiz/types";
import { useSelector, useDispatch } from "react-redux";
import {
  selectActiveQuestion,
  selectSelectedAnswer,
} from "../store/quiz/selectors";
import { useQuizIndicator } from "../hooks/quiz";
import { OptionsList } from "../components/optionsList";
import { Button } from "../components/button";
import { QuizAnswer } from "../store/submission/types";
import { addQuizAnswer } from "../store/submission/actions";
import { selectQuizAnswersCount } from "../store/submission/selectors";
import { goToNextQuestion } from "../store/quiz/actions";
import { QuestionIndicator } from "../components/questionIndicator";
import { Image } from "../components/Image";
import { useRedirect, usePageTransition } from "../hooks/shared";
import { QuizHeading } from "../components/quizHeading";
import { mode } from "../constants";

export function QuizContainer() {
  // Select relevant data
  const question: Question = useSelector(selectActiveQuestion);
  const [noQuestions, activeQuestionNo] = useQuizIndicator();
  const selectedAnswer = useSelector(selectSelectedAnswer);
  const quizAnswersCount = useSelector(selectQuizAnswersCount);

  // Figure out if we should reveal answers
  const revealCorrectAnswer: boolean =
    !(selectedAnswer === undefined) &&
    quizAnswersCount === Number(activeQuestionNo);

  const isQuizAnswerCorrect = question.correctAnswer === selectedAnswer;

  // Get function for redirecting
  const redirect = useRedirect("/submit");
  // setup dispatch function
  const dispatch = useDispatch();

  // Figure out if the quiz answer is correct
  const quizAnswer: QuizAnswer = {
    questionID: String(question.id),
    answerID:
      selectedAnswer === undefined
        ? String(selectedAnswer)
        : `${Number(selectedAnswer) + 1}`,
    correct: isQuizAnswerCorrect,
  };

  // setup transition
  const transitions = usePageTransition(
    {
      activeQuestionNo: activeQuestionNo,
      noQuestions: noQuestions,
      question: question,
      revealCorrectAnswer: revealCorrectAnswer,
      selectedAnswer: selectedAnswer,
      isQuizAnswerCorrect: isQuizAnswerCorrect,
    },
    (item) => item.activeQuestionNo
  );

  return (
    <>
      {transitions.map(({ item, props, key }) => (
        <Wrapper
          key={key}
          style={{
            ...props,
            position: "absolute",
          }}
        >
          <Col1>
            <QuestionIndicator>
              Spørgsmål {item.activeQuestionNo}/{item.noQuestions}
            </QuestionIndicator>
            {item.question.imageURL && (
              <StyledImage
                src={String(item.question.imageURL)}
                maxHeight="540px"
              ></StyledImage>
            )}
            <QuestionWrapper>
              <BoldTextWrapper>
                <QuizHeading
                  revealCorrectAnswer={item.revealCorrectAnswer}
                  isQuizAnswerCorrect={item.isQuizAnswerCorrect}
                >
                  {item.question.text1}
                </QuizHeading>
              </BoldTextWrapper>
              {<h2 style={{ width: "100%" }}>{item.question.text2}</h2>}
            </QuestionWrapper>
          </Col1>
          <Col2>
            <InstructionHeading>Vælg en svarmulighed:</InstructionHeading>
            <StyledOptionsList
              options={item.question.answers}
              correctOption={item.question.correctAnswer}
              reveal={item.revealCorrectAnswer}
            />
            <StyledButton
              onClick={() =>
                item.revealCorrectAnswer
                  ? item.activeQuestionNo === noQuestions
                    ? redirect()
                    : dispatch(goToNextQuestion())
                  : dispatch(addQuizAnswer(quizAnswer))
              }
              disabled={item.selectedAnswer === undefined}
              text={item.revealCorrectAnswer ? "Næste" : "Besvar"}
            />
          </Col2>
        </Wrapper>
      ))}
    </>
  );
}

const Wrapper = styled(FullPageLayout)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${mode.landscape} {
    flex-direction: row;
    justify-content: space-around;
    padding: 0px;
    width: 100vw;
    height: 100vh;
  }
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledOptionsList = styled(OptionsList)`
  margin-top: 3rem;
`;

const StyledButton = styled(Button)`
  align-self: center;
  margin-top: 1rem;
`;

const BoldTextWrapper = styled.div``;

const Column = styled.div`
  display: inherit;
  flex-direction: column;
  justify-content: center;

  @media ${mode.landscape} {
    min-width: calc(50vw - 60px);
    max-width: calc(50vw - 60px);
  }
`;

const Col1 = styled(Column)``;

const Col2 = styled(Column)``;

const StyledImage = styled(Image)`
  height: 540px;
  @media ${mode.landscape} {
    max-width: calc(50vw - 60px);
  }
`;

const InstructionHeading = styled.h1`
  font-size: 3.8rem;
  display: none;

  @media ${mode.landscape} {
    display: initial;
  }
`;
