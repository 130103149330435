import {
  OpenKeyboardAction,
  OPEN_KEYBOARD,
  CloseKeyboardAction,
  CLOSE_KEYBOARD,
  SetLayoutNameAction,
  LayoutName,
  SET_LAYOUT_NAME,
  SET_VIRTUAL_KEYBOARD_CONFIG,
} from "./types";
import { VirtualKeyboardConfig } from "../../googleSheets/types";

export const openKeyboard = (): OpenKeyboardAction => ({
  type: OPEN_KEYBOARD,
});

export const closeKeyboard = (): CloseKeyboardAction => ({
  type: CLOSE_KEYBOARD,
});

export const setLayoutName = (layoutName: LayoutName): SetLayoutNameAction => ({
  type: SET_LAYOUT_NAME,
  layoutName: layoutName,
});

export const setVirtualKeyboardConfig = (
  virtualKeyboardConfig: VirtualKeyboardConfig
) => ({
  type: SET_VIRTUAL_KEYBOARD_CONFIG,
  virtualKeyboardConfig: virtualKeyboardConfig,
});
