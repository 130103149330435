import {
  QuizState,
  QuizActionTypes,
  START_FETCH_QUIZ,
  GOT_QUIZ,
  SET_SELECTED_ANSWER,
  GO_TO_NEXT_QUESTION,
  SHUFFLE_QUIZ,
  CLEAR_QUIZ,
  SET_QUIZ_ID
} from "./types";
import { shuffle } from "../../utils";

// The starting state
const initialState: QuizState = {
  quizId: "",
  questions: [],
  activeQuestion: 0,
  selectedAnswer: undefined,
  fetching: true,
  frontpageConfig: {},
  generalConfig: {
    noQuestions: 0
  },
  submitScreenConfig: {},
  stylingConfig: {
    headingColor: "#fff",
    subheadingColor: "#fff",
    headingCorrectColor: "#fff",
    headingWrongColor: "#fff",
    answerTextColor: "#fff",
    answerCheckboxColor: "#fff",
    answerColor: "#fff",
    questionIndicatorColor: "#fff",
    buttonTextColor: "#fff",
    buttonDisabledTextColor: "#fff",
    buttonBorderColor: "#fff",
    buttonBackgroundColor: "#fff",
    buttonLoadingColor: "#fff",
    inputTextColor: "#fff",
    inputDisabledTextColor: "#fff",
    inputBorderColor: "#fff"
  }
};

// The actual reducer
export function quizReducer(
  prevState: QuizState = initialState,
  action: QuizActionTypes
): QuizState {
  switch (action.type) {
    case GOT_QUIZ:
      return {
        ...prevState,
        ...action.quizConfig,
        fetching: false,
        questions: action.questions
      };

    case START_FETCH_QUIZ:
      return {
        ...prevState,
        fetching: true
      };

    case SET_SELECTED_ANSWER:
      return {
        ...prevState,
        selectedAnswer: action.answerID
      };

    case GO_TO_NEXT_QUESTION:
      return {
        ...prevState,
        selectedAnswer: undefined,
        activeQuestion: prevState.activeQuestion + 1
      };

    case SHUFFLE_QUIZ:
      return {
        ...prevState,
        questions: shuffle(
          prevState.questions.map(question => ({
            ...question,
            answers: shuffle(question.answers)
          }))
        )
      };

    // Should probably be called reset quiz ¯\_(ツ)_/¯
    case CLEAR_QUIZ:
      return {
        ...prevState,
        selectedAnswer: undefined,
        activeQuestion: 0
      };

    case SET_QUIZ_ID:
      return {
        ...prevState,
        quizId: action.quizId
      };

    default:
      return prevState;
  }
}
