// TODO: Refactor the styling, it all turned out a bit messy...
import React from "react";
import styled, { useTheme } from "styled-components";
import { useSpring, animated, config } from "react-spring";

interface OptionProps {
  text: string;
  isCorrect: boolean;
  isSelected: boolean;
  reveal: boolean;
  onClick: () => void;
}

export const Option: React.FunctionComponent<OptionProps> = ({
  text,
  isSelected,
  onClick,
  isCorrect,
  reveal
}) => {
  const theme = useTheme();

  const crossProps = useSpring({
    transform: isSelected ? "scale(1)" : "scale(0)",
    opacity: isSelected ? 1 : 0,
    config: { ...config.wobbly, tension: 280 },
    stroke: reveal ? (isCorrect ? "#89CCA4" : "#CD8989") : theme.answerColor
  });

  //TODO: is there a way to include multiple configs for different properties in the same spring?
  const wrapperProps = useSpring({
    boxShadow: reveal
      ? "0px 0px 0px rgba(0, 0, 0, 0.0)"
      : !isSelected
      ? `0px 6px 15px rgba(0, 0, 0, 0.25)`
      : "0px 6px 20px rgba(0, 0, 0, 0.4)",
    config: { ...config.wobbly, tension: 280 }
  });

  const wrapperRevealProps = useSpring({
    backgroundColor: reveal
      ? isCorrect
        ? "#89CCA4"
        : "#CD8989"
      : theme.answerColor
  });

  const checkBoxProps = useSpring({
    backgroundColor: reveal
      ? isCorrect
        ? "#576B5F"
        : "#6B5757"
    : theme.answerCheckboxColor, 
    fill: reveal
      ? isCorrect
        ? "#576B5F"
        : "#6B5757"
      : theme.answerCheckboxColor
  });

  const textProps = useSpring({
    color: reveal ? (isCorrect ? "#191E1B" : "#1E1919") : theme.answerTextColor
  });

  return (
    <Wrapper
      onClick={onClick}
      style={{ ...wrapperProps, ...wrapperRevealProps }}
    >
      <CheckBox style={checkBoxProps}>
        {reveal ? isCorrect ? <CheckMark style={crossProps} /> : <Cross style={crossProps} /> : <Circle style={crossProps} fill={theme.answerColor} />  }
      </CheckBox>
      <Text style={textProps}>{text}</Text>
    </Wrapper>
  );
};

const Wrapper = styled(animated.div)`
  display: flex;

  height: 152px;
  padding: 10px;
  margin-bottom: 15px;
  max-height: 152px;
  border-radius: 30px;
`;

const CheckBox = styled(animated.div)`
  margin-right: 30px;

  min-width: 147px;

  height: 100%;
  border-radius: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled(animated.p)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`;

interface CrossProps {
  readonly style?: React.CSSProperties;
}
const Cross: React.FunctionComponent<CrossProps> = ({ style }) => {
  return (
    <animated.svg
      width="68"
      height="64"
      viewBox="0 0 68 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <line
        x1="5.76777"
        y1="2.23223"
        x2="65.7678"
        y2="62.2322"
        strokeWidth="5"
      />
      <line
        x1="2.23223"
        y1="62.2322"
        x2="62.2322"
        y2="2.23223"
        strokeWidth="5"
      />
    </animated.svg>
  );
};

const CheckMark: React.FunctionComponent<CrossProps> = ({style}) => {
  return (
    <animated.svg width="55" height="60" viewBox="0 0 55 60" fill="none" xmlns="http://www.w3.org/2000/svg"  style={{...style}}>
      <line x1="1.25" y1="44.8349" x2="22.9006" y2="57.3349" stroke-width="5"/>
      <line x1="19.6473" y1="57.9862" x2="53.4075" y2="0.730218" stroke-width="5"/>
    </animated.svg>
  );
};


interface CircleProps {
  readonly style?: React.CSSProperties;
  fill: string | undefined;
}
const Circle: React.FunctionComponent<CircleProps> = ({style, fill}) => {
  return (
    <animated.svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg" style={{...style}}>
      <circle cx="42.5" cy="42.5" r="42.5" fill={fill}/>
    </animated.svg>
  )
}
