import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./reset.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Provider, useSelector, useDispatch } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { useFetchQuiz } from "./hooks/quiz";
import { LoadingSpinner } from "./components/loadingSpinner";
import { selectFetching, selectStylingConfig } from "./store/quiz/selectors";
import { AppRouter } from "./AppRouter";
import { useQuery } from "./hooks/shared";
import { setQuizId } from "./store/quiz/actions";
import { ThemeProvider } from "styled-components";

function App() {
  // get quizId from query params
  const query = useQuery();

  // Fetch the quiz from google sheets if quizId is given
  const dispatch = useDispatch();

  useEffect(() => {
    const quizId = String(query.get("quizId"));
    dispatch(setQuizId(quizId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useFetchQuiz();
  const isFetching: boolean = useSelector(selectFetching);

  const stylingConfig = useSelector(selectStylingConfig);

  if (isFetching) {
    return (
      <>
        <LoadingSpinner></LoadingSpinner>
        <h2 style={{ textAlign: "center" }}>Loading Quiz</h2>
      </>
    );
  } else {
    /* The browser router is placed seperately from the app router component, 
    in order to enable transitions on location */
    return (
      <ThemeProvider theme={stylingConfig}>
        <AppRouter />
      </ThemeProvider>
    );
  }
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
