import { DefaultTheme } from "styled-components";

export interface AnswerOption {
  text: string;
  id: number;
}

// The base model we want to store in this state
export interface Question {
  id?: string;
  text1?: string;
  text2?: string;
  correctAnswer: number;
  imageURL?: string;
  answers: AnswerOption[];
}

// The configs from google sheets
export interface FrontpageConfig {
  heading?: string;
  subheading?: string;
  image?: string;
  isImageFullBleed?: boolean;
}

export interface GeneralConfig {
  noQuestions: number;
  backgroundImage?: string;
  backgroundImageLandscape?: string;
  frontpageBackgroundImage?: string; 
  frontpageBackgroundImageLandscape?: string; 
  submitpageBackgroundImage?: string; 
  submitpageBackgroundImageLandscape?: string; 
}

export interface SubmitScreenConfig {
  subheading?: string;
  inputPlaceholder?: string;
  image?: string;
  isImageFullBleed?: boolean;
}

export interface QuizConfig {
  frontpageConfig: FrontpageConfig;
  generalConfig: GeneralConfig;
  submitScreenConfig: SubmitScreenConfig;
  stylingConfig: DefaultTheme;
}

// The shape of the question state
export interface QuizState {
  quizId: string;
  questions: Question[];
  activeQuestion: number;
  selectedAnswer?: number;
  fetching: boolean;
  frontpageConfig: FrontpageConfig;
  generalConfig: GeneralConfig;
  submitScreenConfig: SubmitScreenConfig;
  stylingConfig: DefaultTheme;
}

// --- Actions
export const GOT_QUIZ = "[Google Sheets Api] Got Quiz";
export interface GotQuizAction {
  type: typeof GOT_QUIZ;
  questions: Question[];
  quizConfig: QuizConfig;
}

export const START_FETCH_QUIZ = "[Google Sheets Api] Fetch Quiz";
export interface StartFetchQuizAction {
  type: typeof START_FETCH_QUIZ;
}

export const SET_SELECTED_ANSWER = "[Quiz] Set selected answer";
export interface SetSelectedAnswerAction {
  type: typeof SET_SELECTED_ANSWER;
  answerID: number;
}

export const GO_TO_NEXT_QUESTION = "[Quiz] Go to next question";
export interface GoToNextQuestionAction {
  type: typeof GO_TO_NEXT_QUESTION;
}

export const SHUFFLE_QUIZ = "[Quiz] Shuffling quiz";
export interface ShuffleQuizAction {
  type: typeof SHUFFLE_QUIZ;
}

export const CLEAR_QUIZ = "[Quiz] Clearing quiz";
export interface ClearQuizAction {
  type: typeof CLEAR_QUIZ;
}

export const SET_QUIZ_ID = "[Quiz] Setting quiz id";
export interface SetQuizIdAction {
  type: typeof SET_QUIZ_ID;
  quizId: string;
}

export type QuizActionTypes =
  | GotQuizAction
  | StartFetchQuizAction
  | SetSelectedAnswerAction
  | GoToNextQuestionAction
  | ShuffleQuizAction
  | ClearQuizAction
  | SetQuizIdAction;
