import React from "react";
import { Button } from "../components/button";
import { useStartQuiz } from "../hooks/quiz";
import { FullPageLayout } from "../components/fullPageLayout";
import { Image } from "../components/Image";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectFrontpageConfig } from "../store/quiz/selectors";
import { mode, maxWidth } from "../constants";

export const StartContainer: React.FunctionComponent = () => {
  const startQuiz = useStartQuiz();
  const frontpageConfig = useSelector(selectFrontpageConfig);

  return (
    <StyledFullPageLayout>
      <h1>{frontpageConfig.heading}</h1>
      <h2>{frontpageConfig.subheading}</h2>
      <StyledImage
        src={String(frontpageConfig.image)}
        maxHeight="800px"
        fullBleed={Boolean(frontpageConfig.isImageFullBleed)}
      />
      <StyledButton text="Start" onClick={startQuiz} disabled={false} />
    </StyledFullPageLayout>
  );
};

const StyledFullPageLayout = styled(FullPageLayout)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${mode.landscape} {
    align-items: center;
    text-align: center;
    justify-content: center;
    h1,
    h2 {
      max-width: ${maxWidth};
    }
  }
`;

const StyledImage = styled(Image)`
  margin: 3rem 0;
`;

const StyledButton = styled(Button)`
  align-self: center;
  width: 606px;
  height: 160px;
  border-radius: 120px;
  h1 {
    font-size: 7.5rem;
  }

  @media ${mode.landscape} {
    min-height: 150px;
    margin-bottom: 1rem;
  }
`;
