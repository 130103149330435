/* This was originally just stored in the quizContainer, but to avoid memory leak warnings from 
the spring, has been located as its own component */

import React from "react";
import { useSpring, animated } from "react-spring";
import { useTheme } from "styled-components";

interface quizHeadingProps {
  children: React.ReactNode;
  revealCorrectAnswer: boolean;
  isQuizAnswerCorrect: boolean;
}

export const QuizHeading: React.FunctionComponent<quizHeadingProps> = ({
  children,
  revealCorrectAnswer,
  isQuizAnswerCorrect,
}) => {
  const stylingConfig = useTheme();

  const animationProps = useSpring({
    color: stylingConfig.headingColor,
  });

  return <animated.h1 style={animationProps}>{children}</animated.h1>;
};
