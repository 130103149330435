import { AppThunk } from "..";
import { SubmissionState, SendingStatus } from "./types";
import store from "../";
import { selectSubmission } from "./selectors";
import {
  fetchGoogleDoc,
  submissionRowsFromState
} from "../../googleSheets/functions";
import { SubmissionRow } from "../../googleSheets/types";
import { setSendingStatus } from "./actions";

export const sendSubmission = (quizId: string): AppThunk => async dispatch => {
  dispatch(setSendingStatus(SendingStatus.Sending));
  const submission: SubmissionState = selectSubmission(store.getState());
  const submissionRows: SubmissionRow[] = submissionRowsFromState(submission);

  const doc = await fetchGoogleDoc(quizId);
  const submissionsSheet = await doc.sheetsByIndex[2];
  await submissionsSheet.addRows(submissionRows);
  dispatch(setSendingStatus(SendingStatus.Sent));
};
