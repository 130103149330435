import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { useSpring, animated } from "react-spring";
import * as easings from "d3-ease";

interface ButtonProps {
  onClick: () => void;
  text: string;
  disabled: boolean;
  className?: string;
  loading?: boolean;
  loadingText?: string;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  onClick,
  text,
  disabled,
  className,
  loading,
  loadingText
}) => {
  const theme = useTheme();

  const wrapperProps = useSpring({
    borderColor: !disabled
      ? theme.buttonBorderColor
      : theme.buttonBackgroundColor,
    boxShadow: !disabled
      ? "0px 6px 15px rgba(0, 0, 0, 0.25)"
      : "0px 6px 15px rgba(0, 0, 0, 0.0)"
  });

  const [isAnimating, setIsAnimating] = useState(false);

  const wrapperClickProps = useSpring({
    transform: isAnimating || disabled ? "scale(0.9)" : "scale(1)",
    boxShadow:
      isAnimating || disabled
        ? "0px 6px 15px rgba(0, 0, 0, 0.0)"
        : "0px 6px 15px rgba(0, 0, 0, 0.25)",
    onRest: () => setIsAnimating(false),
    config: {
      duration: isAnimating ? 100 : 750,

      easing: isAnimating ? easings.easePoly : easings.easeElastic
    }
  });

  const h1Props = useSpring({
    color: disabled ? theme.buttonDisabledTextColor : theme.buttonTextColor
  });

  return (
    <Wrapper
      className={className}
      onClick={
        disabled || loading
          ? () => {}
          : () => {
              onClick();
              setIsAnimating(true);
            }
      }
      disabled={disabled}
      // Has to be passed as a string for the browser to not throw warnings,
      // due to the isLoading property not being a standard dom property
      isloading={String(loading)}
      style={{ ...wrapperProps, ...wrapperClickProps }}
    >
      <animated.h1 style={h1Props}>
        {!loading ? text : loadingText ? loadingText : ""}
      </animated.h1>
    </Wrapper>
  );
};

interface WrapperProps {
  readonly disabled: boolean;
  readonly isloading: string;
}

const Wrapper = styled(animated.div)<WrapperProps>`
  background-color: ${({ theme }) => theme.buttonBackgroundColor};
  width: 366px;
  height: 91px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 10px solid;
  ${({ isloading }) =>
    isloading === "true"
      ? `
          animation-name: LoadingAnmiation;
          animation-duration: 1.3s;
          animation-iteration-count: infinite;
  
          h1 {
            animation-name: LoadingAnmiation;
            animation-duration: 1.3s;
            animation-iteration-count: infinite;
  }`
      : ""}
  ${({ theme }) => `
  @keyframes LoadingAnmiation {
    0%,
    100% {
      border-color: ${theme.buttonLoadingColor};
      color: ${theme.buttonLoadingColor};
    }

    50% {
      border-color: ${theme.buttonBackgroundColor};
      color: ${theme.buttonBackgroundColor};
    }
  }
  `}
`;
