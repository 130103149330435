import { useHistory, useLocation } from "react-router-dom";
import { useTransition } from "react-spring";
import { useContext } from "react";
import {useSelector} from 'react-redux';
import { ThemeContext } from "styled-components";
import { selectGeneralConfig } from "../store/quiz/selectors"; 

// custom hook stolen from: https://reacttraining.com/react-router/web/example/query-parameters
export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export const useRedirect = (path: string, delay?: number): (() => void) => {
  const query = useQuery().toString();
  const history = useHistory();
  return () => {
    setTimeout(
      () => {
        history.push(`${path}${!query ? "" : `?${query}`}`);
      },
      delay === undefined ? 0 : Number(delay)
    );
  };
};

export function usePageTransition(
  transitionParam: any,
  param2: ((location: any) => any) | null
) {
  return useTransition(transitionParam, param2, {
    from: { transform: "translate3d(100%,0,0)" },
    enter: { transform: "translate3d(0%,0,0)" },
    leave: { transform: "translate3d(-100%,0,0)" }
  });
}

export function useTheme() {
  const theme = useContext(ThemeContext);
  return theme;
}

// Returns the portrait backgroundImage to use for the current page 
export function useBackgroundImage() {
  const {pathname} = useLocation();  
  const generalConfig = useSelector(selectGeneralConfig); 
  let backgroundImage = generalConfig?.backgroundImage;
  let backgroundImageLandscape = generalConfig?.backgroundImageLandscape;
  if (pathname === "/") {
    if (generalConfig?.frontpageBackgroundImage){
      backgroundImage = generalConfig.frontpageBackgroundImage
    } 
    if (generalConfig?.frontpageBackgroundImageLandscape) {
      backgroundImageLandscape = generalConfig.frontpageBackgroundImageLandscape;
    }
  } else if (pathname === "/submit") {
    if (generalConfig?.submitpageBackgroundImage){
      backgroundImage = generalConfig.submitpageBackgroundImage
    } 
    if (generalConfig?.submitpageBackgroundImageLandscape) {
      backgroundImageLandscape = generalConfig.submitpageBackgroundImageLandscape;
    }
  }

  return [backgroundImage, backgroundImageLandscape];
}
