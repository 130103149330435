import { SubmissionState, SendingStatus } from "./types";
import { RootState } from "..";

export const selectSubmission = (state: RootState): SubmissionState =>
  state.submission;

export const selectQuizAnswersCount = (state: RootState): number =>
  selectSubmission(state).quizAnswers.length;

export const selectNoCorrectQuestions = (state: RootState): number =>
  selectSubmission(state).quizAnswers.filter(quizAnswer => quizAnswer.correct)
    .length;

export const selectSendingStatus = (state: RootState): SendingStatus =>
  selectSubmission(state).sendingStatus;
