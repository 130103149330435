import styled from "styled-components";
import { sidePadding } from "./fullPageLayout";
import { mode, maxWidth, width } from "../constants";

interface ImageProps {
  src: string;
  maxHeight: string;
  fullBleed?: boolean;
}

export const Image = styled.div<ImageProps>`
  height: calc(100%);
  max-height: ${({ maxHeight }) => maxHeight};

  ${({ fullBleed }) =>
    fullBleed
      ? `
        transform: translateX(-${sidePadding});
        width: ${width.portrait};
        @media ${mode.landscape} {
          transform: translateX(0px); 
        }
      `
      : ""}

  @media ${mode.landscape} {
    width: ${maxWidth}
  }

  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  margin-bottom: 3rem;
`;
