import React from "react";
import { Option } from "../components/option";
import styled from "styled-components";
import { selectSelectedAnswer } from "../store/quiz/selectors";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedAnswer } from "../store/quiz/actions";
import { AnswerOption } from "../store/quiz/types";

interface OptionsListProps {
  options: AnswerOption[];
  correctOption: number;
  className?: string;
  reveal: boolean;
}

export const OptionsList: React.FunctionComponent<OptionsListProps> = ({
  options,
  correctOption,
  className,
  reveal
}) => {
  const selectedAnswer = useSelector(selectSelectedAnswer);
  const dispatch = useDispatch();

  return (
    <Wrapper className={className}>
      {options.map(option => (
        <Option
          key={option.id}
          text={option.text}
          isCorrect={option.id === correctOption}
          isSelected={option.id === selectedAnswer}
          onClick={() =>
            reveal ? () => {} : dispatch(setSelectedAnswer(option.id))
          }
          reveal={reveal}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div``;
