import React, { useState, useEffect, useRef } from "react";
import { FullPageLayout } from "../components/fullPageLayout";
import styled from "styled-components";
import { QuestionIndicator } from "../components/questionIndicator";
import {
  selectNoQuestions,
  selectSubmitScreenConfig,
} from "../store/quiz/selectors";
import { useSelector } from "react-redux";
import {
  selectNoCorrectQuestions,
  selectSendingStatus,
} from "../store/submission/selectors";
import { SubmitScreenConfig } from "../store/quiz/types";
import { Input } from "../components/input";
import { Button } from "../components/button";
import { Image } from "../components/Image";
//import { VirtualKeyboard } from "../components/virtualKeyboard";
import { SendingStatus } from "../store/submission/types";
import { useSubmitQuizResults } from "../hooks/submission";
import { useRedirect } from "../hooks/shared";
import { mode, maxWidth } from "../constants";

export const SubmitContainer: React.FunctionComponent = () => {
  // Select necessary info
  const submitScreenConfig: SubmitScreenConfig = useSelector(
    selectSubmitScreenConfig
  );
  const noQuestions = useSelector(selectNoQuestions);
  const noCorrectQuestions = useSelector(selectNoCorrectQuestions);
  const sendingStatus = useSelector(selectSendingStatus);

  // Setup submitQuizResults function
  const submitQuizResults = useSubmitQuizResults();

  // Setup state for the input value, such that it can be passed to submitQuizResults
  const [inputValue, setInputValue] = useState(undefined);

  // Redirect if the quiz has been submitted
  const redirect = useRedirect("/success", 0);
  useEffect(() => {
    if (sendingStatus === SendingStatus.Sent) {
      redirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingStatus]);

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Wrapper>
      <StyledQuestionIndicator>
        Færdig: {noCorrectQuestions} ud af {noQuestions} rigtige!
      </StyledQuestionIndicator>
      <Text>{submitScreenConfig.subheading}</Text>
      <InputButtonWrapper>
        <StyledInput
          placeholder={submitScreenConfig.inputPlaceholder}
          ref={inputRef}
          onChange={setInputValue}
        />
        <StyledButton
          text="Indsend"
          disabled={!inputValue}
          loading={sendingStatus === SendingStatus.Sending}
          loadingText="Sender"
          onClick={() => submitQuizResults(String(inputValue))}
        />
      </InputButtonWrapper>
      {submitScreenConfig.image && (
        <Image
          src={String(submitScreenConfig.image)}
          maxHeight={"100%"}
          fullBleed={submitScreenConfig.isImageFullBleed}
        ></Image>
      )}
          {/*<VirtualKeyboard targetRef={inputRef} onChange={setInputValue} />*/}
    </Wrapper>
  );
};

const Wrapper = styled(FullPageLayout)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${mode.landscape} {
    align-items: center;
    text-align: center;
    justify-content: start;
    h1,
    h2 {
      max-width: ${maxWidth};
    }
  }
`;

const Text = styled.h2`
  font-weight: 500;
  font-size: 4.2rem;
  margin-bottom: 3rem;
`;

const StyledInput = styled(Input)`
  @media ${mode.landscape} {
    width: 780px;
  }
`;

const StyledButton = styled(Button)`
  align-self: center;
  margin-top: 3rem;
  margin-bottom: 3rem;

  @media ${mode.landscape} {
    margin: 0;
    height: 120px;
    border-radius: 120px;
    margin-left: 20px;
  }
`;

const InputButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${mode.landscape} {
    width: ${maxWidth};
    flex-direction: row;
    justify-content: center;
  }
`;

const StyledQuestionIndicator = styled(QuestionIndicator)`
  color: ${({ theme }) => theme.headingColor} !important;
`;
