// Models
export interface QuizAnswer {
  questionID: string;
  answerID: string;
  correct: boolean;
}

export enum SendingStatus {
  NotSent,
  Sending,
  Sent
}

// State
export interface SubmissionState {
  userIdentifier: string;
  quizAnswers: QuizAnswer[];
  sendingStatus: SendingStatus;
}

// Actions
export const ADD_QUIZ_ANSWER = "[Submission] Add quiz answer";
export interface AddQuizAnswerAction {
  type: typeof ADD_QUIZ_ANSWER;
  quizAnswer: QuizAnswer;
}

export const SET_USER_IDENTIFIER = "[Submission] Set user identifier";
export interface SetUserIdentifierAction {
  type: typeof SET_USER_IDENTIFIER;
  userIdentifier: string;
}

export const SEND_SUBMISSION = "[Google Sheets Api] Send submission";
export interface SendSubmissionAction {
  type: typeof SEND_SUBMISSION;
}

export const CLEAR_SUBMISSION = "[Submission] Clear Submission";
export interface ClearSubmissionAction {
  type: typeof CLEAR_SUBMISSION;
}

export const SET_SENDING_STATUS = "[Submission] Setting sending status";
export interface SetSendingAction {
  type: typeof SET_SENDING_STATUS;
  sendingStatus: SendingStatus;
}

export type SubmissionActionTypes =
  | AddQuizAnswerAction
  | SendSubmissionAction
  | SetUserIdentifierAction
  | ClearSubmissionAction
  | SetSendingAction;
