import React from "react";
import "./reset.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { Switch, Route, useLocation } from "react-router-dom";
import { QuizContainer } from "./containers/quizContainer";
import { SubmitContainer } from "./containers/submitContainer";
import { StartContainer } from "./containers/startContainer";
import { SuccessContainer } from "./containers/successContainer";
import { animated } from "react-spring";
import { usePageTransition } from "./hooks/shared";

export const AppRouter: React.FunctionComponent = () => {
  // setup transition
  const location = useLocation();

  const transitions = usePageTransition(
    location,
    location => location.pathname
  );

  return (
    <>
      {transitions.map(({ item: location, props, key }) => (
        <animated.div
          style={{
            ...props,
            position: "absolute",
            width: "100%",
            height: "100%"
          }}
          key={key}
        >
          <Switch location={location}>
            <Route path="/quiz">
              <QuizContainer />
            </Route>
            <Route path="/submit">
              <SubmitContainer />
            </Route>
            <Route path="/success">
              <SuccessContainer />
            </Route>
            <Route path="/">
              <StartContainer />
            </Route>
          </Switch>
        </animated.div>
      ))}
    </>
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
