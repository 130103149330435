import {
  AddQuizAnswerAction,
  QuizAnswer,
  ADD_QUIZ_ANSWER,
  SetUserIdentifierAction,
  SET_USER_IDENTIFIER,
  ClearSubmissionAction,
  CLEAR_SUBMISSION,
  SetSendingAction,
  SendingStatus,
  SET_SENDING_STATUS
} from "./types";

export const addQuizAnswer = (quizAnswer: QuizAnswer): AddQuizAnswerAction => ({
  type: ADD_QUIZ_ANSWER,
  quizAnswer: quizAnswer
});

export const setUserIdentifier = (
  userIdentifier: string
): SetUserIdentifierAction => ({
  type: SET_USER_IDENTIFIER,
  userIdentifier: userIdentifier
});

export const clearSubmission = (): ClearSubmissionAction => ({
  type: CLEAR_SUBMISSION
});

export const setSendingStatus = (
  sendingStatus: SendingStatus
): SetSendingAction => ({
  type: SET_SENDING_STATUS,
  sendingStatus: sendingStatus
});
