import {
  SubmissionState,
  SubmissionActionTypes,
  ADD_QUIZ_ANSWER,
  SET_USER_IDENTIFIER,
  CLEAR_SUBMISSION,
  SET_SENDING_STATUS,
  SendingStatus,
} from "./types";

const initialState: SubmissionState = {
  userIdentifier: "",
  quizAnswers: [],
  sendingStatus: SendingStatus.NotSent,
};

export function submissionReducer(
  prevState: SubmissionState = initialState,
  action: SubmissionActionTypes
): SubmissionState {
  switch (action.type) {
    case ADD_QUIZ_ANSWER:
      return {
        ...prevState,
        quizAnswers: [...prevState.quizAnswers, action.quizAnswer],
      };
    case SET_USER_IDENTIFIER:
      return {
        ...prevState,
        userIdentifier: String(action.userIdentifier),
      };
    case CLEAR_SUBMISSION:
      return initialState;
    case SET_SENDING_STATUS:
      return {
        ...prevState,
        sendingStatus: action.sendingStatus,
      };
  }

  return prevState;
}
