import React from "react";
import styled from "styled-components";

interface InputProps {
  placeholder?: string;
  className?: string;
  ref: React.MutableRefObject<any>;
  onChange: any;
}
export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ placeholder, className, onChange }, ref) => {
    return (
      <InputField
        placeholder={placeholder === undefined ? "" : String(placeholder)}
        className={className}
        onChange={(e) => onChange(e.target.value)}
        ref={ref}
      />
    );
  }
);

const InputField = styled.input`
  height: 120px;
  width: calc(100% - 20px);
  padding: 0px;
  border-radius: 120px;
  border: 10px solid;
  font-size: 50px;
  text-align: center;
  ${({ theme }) => `
    border-color: ${theme.inputBorderColor};
    color: ${theme.inputTextColor};
    ::placeholder {
      color: ${theme.inputDisabledTextColor};
    }
  `}
`;
