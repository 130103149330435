import { useDispatch, useSelector } from "react-redux";
import { setUserIdentifier } from "../store/submission/actions";
import { sendSubmission } from "../store/submission/thunks";
import { selectQuizId } from "../store/quiz/selectors";

export const useSubmitQuizResults = (): ((userIdentifier: string) => void) => {
  const quizId = useSelector(selectQuizId);
  const dispatch = useDispatch();
  return (userIdentifier: string) => {
    dispatch(setUserIdentifier(userIdentifier));
    dispatch(sendSubmission(quizId));
  };
};
