export const width = {
  landscape: "1920px",
  portrait: "1080px",
};

export const height = {
  landscape: "1080px",
  portrait: "1920px",
};

export const mode = {
  landscape: `(min-width: ${width.landscape})`,
  portrait: ` (min-width: ${width.portrait})`,
};

export const maxWidth = "1286px";
