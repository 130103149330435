import {
  VirtualKeyboardState,
  VirtualKeyboardActionTypes,
  OPEN_KEYBOARD,
  CLOSE_KEYBOARD,
  LayoutName,
  SET_LAYOUT_NAME,
  SET_VIRTUAL_KEYBOARD_CONFIG,
} from "./types";

const initialState: VirtualKeyboardState = {
  isOpen: false,
  layoutName: LayoutName.DEFAULT,
  config: {
    layout: {
      default: [],
      shift: [],
      numbers: [],
    },
    allLayoutsCss: "",
    defaultLayoutCss: "",
    shiftLayoutCss: "",
    numbersLayoutCss: "",
  },
};

export function virtualKeyboardReducer(
  prevState: VirtualKeyboardState = initialState,
  action: VirtualKeyboardActionTypes
) {
  switch (action.type) {
    case OPEN_KEYBOARD:
      return {
        ...prevState,
        isOpen: true,
      };
    case CLOSE_KEYBOARD:
      return {
        ...prevState,
        isOpen: false,
      };
    case SET_LAYOUT_NAME:
      return {
        ...prevState,
        layoutName:
          // handle shift toggling
          action.layoutName === LayoutName.SHIFT &&
          prevState.layoutName === LayoutName.SHIFT
            ? LayoutName.DEFAULT
            : action.layoutName,
      };
    case SET_VIRTUAL_KEYBOARD_CONFIG:
      return {
        ...prevState,
        config: action.virtualKeyboardConfig,
      };
    default:
      return prevState;
  }
}
