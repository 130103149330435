import React, { useEffect } from "react";
import { FullPageLayout } from "../components/fullPageLayout";
import { useRedirect } from "../hooks/shared";
import { maxWidth, mode } from "../constants";
import styled from "styled-components";

export const SuccessContainer: React.FunctionComponent = () => {
  const redirect = useRedirect("/", 5000);
  useEffect(() => {
    redirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StyledFullPageLayout>
      <h1> Din besvarelse blev sendt!</h1>
      <h2> Du vil automatisk blive sendt tilbage til start. </h2>
    </StyledFullPageLayout>
  );
};

const StyledFullPageLayout = styled(FullPageLayout)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${mode.landscape} {
    align-items: center;
    text-align: center;
    justify-content: center;
    h1,
    h2 {
      max-width: ${maxWidth};
    }
  }
`;
