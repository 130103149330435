import React from "react";
import styled from "styled-components";
import { animated } from "react-spring";
import { mode, width, height } from "../constants";
import {useBackgroundImage} from '../hooks/shared'; 

interface FullPageLayoutProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const FullPageLayout: React.FunctionComponent<FullPageLayoutProps> = (
  props
) => {
  const [backgroundUrl, backgroundLandscapeUrl] = useBackgroundImage(); 

  return (
    <Wrapper
      className={props.className}
      background={backgroundUrl}
      backgroundlandscape={backgroundLandscapeUrl}
      style={props.style}
    >
      {props.children}
    </Wrapper>
  );
};

interface WrapperProps {
  readonly background: string | undefined;
  readonly backgroundlandscape: string | undefined;
}

export const sidePadding: string = "120px";

const Wrapper = styled(animated.div)<WrapperProps>`
  padding: 15px ${sidePadding};

  background-image: ${(props) =>
    props.background ? `Url(${props.background})` : ""};

  @media ${mode.landscape} {
    background-image: ${(props) =>
      props.background ? `Url(${props.backgroundlandscape})` : ""};
  }

  ${({ theme }) => `
    h1 {
      color: ${theme.headingColor};
    }

    h2 {
      color: ${theme.subheadingColor};
    }
  `}


  width: calc(${width.portrait} - 120px * 2);
  height: calc(${height.portrait} - 15px * 2);

  @media ${mode.landscape} {
    width: calc(${width.landscape} - 120px * 2);
    height: calc(${height.landscape} - 15px * 2);
  }
`;
