import { combineReducers, createStore, applyMiddleware, Action } from "redux";
import { quizReducer } from "./quiz/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkAction } from "redux-thunk";
import { submissionReducer } from "./submission/reducers";
import { virtualKeyboardReducer } from "./virtualKeyboard/reducers";

const rootReducer = combineReducers({
  quiz: quizReducer,
  submission: submissionReducer,
  virtualKeyboard: virtualKeyboardReducer,
});

const middleware = [thunk];

// Infer the type of the root reduer
export type RootState = ReturnType<typeof rootReducer>;

// Create a base thunk type
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<any>
>;

//Enable devtools, apply middleware
export default createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
