import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuiz } from "../store/quiz/thunks";
import {
  selectNoQuestions,
  selectActiveQuestionNo,
  selectQuizId
} from "../store/quiz/selectors";
import { shuffleQuiz, clearQuiz } from "../store/quiz/actions";
import { clearSubmission } from "../store/submission/actions";
import { useRedirect } from "./shared";

export function useFetchQuiz(): void {
  const quizId = useSelector(selectQuizId);

  const dispatch = useDispatch();
  useEffect(() => {
    if (quizId !== "") {
      dispatch(fetchQuiz(quizId));
    }
  }, [dispatch, quizId]);
}

export function useQuizIndicator(): number[] {
  const noQuestions = useSelector(selectNoQuestions);
  const activeQuestionNo = useSelector(selectActiveQuestionNo);
  return [noQuestions, activeQuestionNo];
}

export function useStartQuiz(): () => void {
  const dispatch = useDispatch();
  const redirect = useRedirect("/quiz", 0);

  return () => {
    dispatch(clearQuiz());
    dispatch(shuffleQuiz());
    dispatch(clearSubmission());
    redirect();
  };
}
