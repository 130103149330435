import {
  Question,
  GOT_QUIZ,
  START_FETCH_QUIZ,
  QuizConfig,
  GotQuizAction,
  StartFetchQuizAction,
  SetSelectedAnswerAction,
  SET_SELECTED_ANSWER,
  GoToNextQuestionAction,
  GO_TO_NEXT_QUESTION,
  ShuffleQuizAction,
  SHUFFLE_QUIZ,
  ClearQuizAction,
  CLEAR_QUIZ,
  SetQuizIdAction,
  SET_QUIZ_ID
} from "./types";

export const gotQuiz = (
  questions: Question[],
  quizConfig: QuizConfig
): GotQuizAction => ({
  type: GOT_QUIZ,
  questions,
  quizConfig
});

export const startFetchQuiz = (): StartFetchQuizAction => ({
  type: START_FETCH_QUIZ
});

export const setSelectedAnswer = (
  answerID: number
): SetSelectedAnswerAction => ({
  type: SET_SELECTED_ANSWER,
  answerID
});

export const goToNextQuestion = (): GoToNextQuestionAction => ({
  type: GO_TO_NEXT_QUESTION
});

export const shuffleQuiz = (): ShuffleQuizAction => ({
  type: SHUFFLE_QUIZ
});

export const clearQuiz = (): ClearQuizAction => ({
  type: CLEAR_QUIZ
});

export const setQuizId = (quizId: string): SetQuizIdAction => ({
  type: SET_QUIZ_ID,
  quizId: quizId
});
