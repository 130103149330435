import {
  QuizState,
  Question,
  SubmitScreenConfig,
  FrontpageConfig,
  GeneralConfig
} from "./types";
import { RootState } from "..";
import { DefaultTheme } from "styled-components";

const selectQuiz = (state: RootState): QuizState => state.quiz;

export const selectBackgroundImage = (state: RootState): string | undefined =>
  selectQuiz(state).generalConfig.backgroundImage;

export const selectGeneralConfig = (state: RootState): GeneralConfig | undefined => selectQuiz(state).generalConfig;

export const selectBackgroundImageLandscape = (
  state: RootState
): string | undefined =>
  selectQuiz(state).generalConfig.backgroundImageLandscape;

export const selectFetching = (state: RootState): boolean =>
  selectQuiz(state).fetching;

export const selectActiveQuestion = (state: RootState): Question => {
  const { questions, activeQuestion } = selectQuiz(state);
  return questions[activeQuestion];
};

export const selectNoQuestions = (state: RootState): number =>
  selectQuiz(state).generalConfig.noQuestions;

export const selectActiveQuestionNo = (state: RootState): number =>
  selectQuiz(state).activeQuestion + 1;

export const selectSelectedAnswer = (state: RootState): number | undefined =>
  selectQuiz(state).selectedAnswer;

export const selectSubmitScreenConfig = (
  state: RootState
): SubmitScreenConfig => selectQuiz(state).submitScreenConfig;

export const selectFrontpageConfig = (state: RootState): FrontpageConfig =>
  selectQuiz(state).frontpageConfig;

export const selectQuizId = (state: RootState): string =>
  selectQuiz(state).quizId;

export const selectStylingConfig = (state: RootState): DefaultTheme =>
  selectQuiz(state).stylingConfig;
